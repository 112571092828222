import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionWrapper = styled.div`
  padding: 170px 0!important;
 /* overflow: hidden;*/
  @media (max-width: 1440px) {
    padding: 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 30px 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .image {
    width: 50%;
    padding-left: 50px;
    @media only screen and (max-width: 1440px) {
      padding-left: 0px;
    }
    @media only screen and (max-width: 991px) {
      width: 50%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 40px;
      padding-left: 100px;
    }
    img {
      width: 100%;
      object-fit: cover;
      @media only screen and (max-width: 1440px) {
        width: 80%;
      }
    }
  }
  .content {
    width: 100%;
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 50px;
    }
    h2 {
     /* color: ${themeGet('colors.white', 'fff')};*/
     color:#3FD2C7;
      font-size: 38px;
      line-height: 1.2;
      font-weight: 300;
      letter-spacing: -0.025em;
      margin-bottom: 27px;
      
/*max-width: 370px;*/
      @media only screen and (max-width: 1440px) {
        font-size: 38px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 40px;
        max-width: 100%;
        text-align: center;
      }
      @media only screen and (max-width: 480px) {
        font-size: 30px;
      }
    }
    p, li {
      font-size: 16px;
      line-height: 28px;
      color: #ADADC2;
/*max-width: 400px;*/
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        text-align: center;
      }
      a{ color:#3FD2C7;}
      
    }
    li{    list-style-type: decimal;}
    li li{ list-style-type: disc; margin-left:20px;}
  }

*,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}


.login-wrap{
  width:100%;
  margin:auto;
  max-width:525px;
  min-height:670px;
  height:auto;
  position:relative;
  box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.login-html{
  width:100%;
  height:100%;
  position:absolute;
  padding:50px 20px 50px 20px;
  
}
.login-html .sign-in-htm{
  top:0;
  left:0;
  right:0;
  bottom:0;
  position:absolute;
  /*transform: rotateX(180deg);  
  backface-visibility: visible;*/
  
}

.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check{
  display:none;
}
.login-form .group p{ font-size:13px;}

.login-html .tab,
.login-form .group .label,
.login-form .group .button{
}
.login-html .tab{
  font-size:22px;
  margin-right:15px;
  padding-bottom:5px;
  margin:0 15px 10px 0;
  display:inline-block;
  border-bottom:2px solid transparent;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab{
  color:#fff;
  border-color:#1161ee;
}
.login-form{
  min-height:100vh;
  height:auto;
  position:relative;
  perspective:1000px;
  transform-style:preserve-3d;
}
.login-form .group{
  margin-bottom:25px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
  width:100%;
  color:#fff;
  display:block;
}
.login-form .group .input
{
  border:none;
  padding:12px 20px 12px 0;
  border-radius:0;
  background:transparent;
  border-bottom:solid 1px #2B2B4A;
}

.login-form .group .button{
  border:solid 1px #D1D1E2;
  padding:15px 20px;
  border-radius:12px;
  background:transparent;
}
.login-form .group input[data-type="password"]{
  text-security:circle;
  -webkit-text-security:circle;
}
.login-form .group .label{
  color:#aaa;
  font-size:13px; line-height:22px;
}
.login-form .group .button{
  background:#794EDD;
  border:none;
}
.login-form .group label .icon{
  width:15px;
  height:15px;
  border-radius:2px;
  position:relative;
  display:inline-block;
  background:rgba(255,255,255,.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after{
  content:'';
  width:10px;
  height:2px;
  background:#fff;
  position:absolute;
  transition:all .2s ease-in-out 0s;
}
.login-form .group label .icon:before{
  left:3px;
  width:5px;
  bottom:6px;
  transform:scale(0) rotate(0);
}
.login-form .group label .icon:after{
  top:6px;
  right:0;
  transform:scale(0) rotate(0);
}
.login-form .group .check:checked + label{
  color:#aaa; font-size:13px; vertical-align: middle;
}
.login-form .group .check:checked + label .icon{
  background:#794EDD; margin-right:5px;
}
.login-form .group .check:checked + label .icon:before{
  transform:scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after{
  transform:scale(1) rotate(-45deg);
}
.login-html .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
  transform:rotate(0);
}
.login-html .sign-up:checked + .tab + .login-form .sign-up-htm{
  transform:rotate(0);
}

.hr{
  height:2px;
  margin:60px 0 50px 0;
  background:rgba(255,255,255,.2);
}
.foot-lnk{
  text-align:center;
}
/*.page-details{ width:50%; float:left; height:100vh}
form{ float:left; width:50%; }*/
form .login-html { background:radial-gradient(circle at center,#161839 0,#00010d 60%); height:auto}  
.page-details{ text-align:center; padding-top:150px; /*background:#161839;*/padding:20px; border-radius:25px; }
h2{ font-weight:normal; font-size:16px;}
h3{ color:#A1a1a1; text-align:center; font-weight:normal; line-height:30px;  margin-left:30px; margin-right:30px;}
.submitmessage{ border:solid 1px #3FD2C7; padding:20px; text-align: center; border-radius:5px;}

@media only screen and (max-width: 768px) {
    .cSsIef .content p, .cSsIef .content li {
        max-width: 100%;
        text-align: center; 
    }
}
`;

export default SectionWrapper;
