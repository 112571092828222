import React, { useState } from 'react';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './joinwaitlist.style';

const Joinwaitlist = () => {
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    title: '',
    company: '',
    linkedinUrl: '',
    description: '',
    subscribe: true,
  });

  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  // Update form data on input change
  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value,
    });
    // Clear any previous error for the current field
    setErrors((prevErrors) => ({ ...prevErrors, [id]: '' }));
  };

  // Validation functions
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return emailRegex.test(email);
  };

  const validateLinkedInUrl = (url) => {
    const urlRegex = /^(https?:\/\/)?(www\.)?(linkedin\.com\/.*)$/; // Basic LinkedIn URL regex
    return urlRegex.test(url);
  };

  // Submit form data to NoCodeDB
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the form's default submit behavior
    setMessage(''); // Clear previous message
    setErrors({}); // Clear previous errors

    // Validate fields
    let formErrors = {};
    if (!formData.firstname) {
      formErrors.firstname = 'Full Name is required.';
    }
    if (!formData.title) {
      formErrors.title = 'Title is required.';
    }
    if (!formData.company) {
      formErrors.company = 'Company is required.';
    }
    if (!validateEmail(formData.email)) {
      formErrors.email = 'Please enter a valid email address.';
    }
    if (!validateLinkedInUrl(formData.linkedinUrl)) {
      formErrors.linkedinUrl = 'Please enter a valid LinkedIn URL.';
    }

    // If there are errors, set them and return
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const response = await fetch('https://app.nocodb.com/api/v2/tables/mbz5oaiswn4g1tf/records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Keep this as application/json
          'xc-token': '5JbAYLnScNa5MPqax8t0zLRnPWVoSacZ346Xi9_F',
        },
        body: JSON.stringify({
          "Email": formData.email,
          "Full Name": formData.firstname,
          "Tital": formData.title,
          "Company": formData.company,
          "LinkedinUrl": formData.linkedinUrl,
          "How do you see Agentic AI benefiting your work?": formData.description,
          "Subscribe for Newsletter": formData.subscribe,
        }),
      });

      if (response.ok) {
        setMessage('Thank you! You have successfully joined the waitlist.');
        // Reset form fields
        setFormData({
          email: '',
          firstname: '',
          title: '',
          company: '',
          linkedinUrl: '',
          description: '',
          subscribe: true,
        });
        setTimeout(() => {
          window.location.href = '/'; // Redirect to home
        }, 2000); // 2-second delay
      } else {
        setMessage('There was an error submitting your form. Please try again.');
      }
    } catch (error) {
      setMessage('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <div className="content">
            <div className='page-details'><Heading content="Join Wait List" />
            <h3>We're excited to have you join the journey of transforming enterprises with AI. <br/>Please take a moment to fill in the information below so we can better serve your needs:</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="login-wrap">
                <div className="login-html">
                  <div className="login-form">
                    <div className="sign-in-htm">
                    <div className="group">
                        <label htmlFor="firstname" className="label">Full Name</label>
                        <input id="firstname" type="text" className="input" value={formData.firstname} onChange={handleChange} />
                        {errors.firstname && <p style={{ color: 'red' }}>{errors.firstname}</p>}
                      </div>
                      <div className="group">
                        <label htmlFor="title" className="label">Title</label>
                        <input id="title" type="text" className="input" value={formData.title} onChange={handleChange} />
                        {errors.title && <p style={{ color: 'red' }}>{errors.title}</p>}
                      </div>

                      <div className="group">
                        <label htmlFor="email" className="label">Work Email</label>
                        <input id="email" type="email" className="input" value={formData.email} onChange={handleChange} />
                        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                      </div>
                      
                      <div className="group">
                        <label htmlFor="company" className="label">Company</label>
                        <input id="company" type="text" className="input" value={formData.company} onChange={handleChange} />
                        {errors.company && <p style={{ color: 'red' }}>{errors.company}</p>}
                      </div>
                      <div className="group">
                        <label htmlFor="linkedinUrl" className="label">LinkedIn URL</label>
                        <input id="linkedinUrl" type="text" className="input" value={formData.linkedinUrl} onChange={handleChange} />
                        {errors.linkedinUrl && <p style={{ color: 'red' }}>{errors.linkedinUrl}</p>}
                      </div>
                      <div className="group">
                        <label htmlFor="description" className="label">What sparked your interest in Nadi?</label>
                        <textarea id="description" className="input" value={formData.description} onChange={handleChange}></textarea>
                      </div>
                      <div className="group">
                        <input id="subscribe" type="checkbox" className="check" checked={formData.subscribe} onChange={handleChange} />
                        <label htmlFor="subscribe"><span className="icon"></span> Subscribe to Newsletter</label>
                      </div>
                      <div className="group">
                        <p>By filling out and submitting this form, you are agreeing to our <a href='/policy'>Privacy Policy</a> and to receiving email communications from NadiAI regarding events, webinars, research, and more. You can unsubscribe any time.</p>
                      </div>
                      <div className="group">
                        <p><input type="submit" className="button" value="Join Wait List" /></p>
                      </div>
                      {message && <p className='submitmessage'>{message}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Joinwaitlist;
